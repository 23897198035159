@import "./variable.css";

body {
  overflow: auto !important;
  font-size: 14px !important;
}

.curved-border {
  border: 2px solid var(--main-color); 
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--white-color); 
}

.equal-width-field {
  width: 310px; 
}

.form-control {
  font-size: 14px !important;
}

.error-input {
  border: 1px solid var(--light-red-color) !important;
  border-radius: 0.6rem !important;
}

.form-check-input:checked {
  background-color: var(--blue-color) !important;
  border-color: var(--blue-color) !important;
}

.error {
  color: var(--light-red-color);
  font-size: 12.25px !important;
  margin-top: 0.25rem !important;
}

.scrollable-view {
  height: 360px;
  max-height: 360px;
  overflow-y: scroll;
}

.default-height {
  height: 290px;
}

.mvno-dashboard-height {
  height: 270px;
}


.status {
  width: 80px !important;
}

.approve-or-reject {
  width: 65px !important
}

.field-length {
  width: 140px !important;
}

.field-length2 {
  width: 120px !important;
}

.field-length3 {
  width: 100px !important;
}

.switch-state {
  width: 160px !important;
}

.period-option {
  margin-top: 27px !important;
}

/* 
.css-1nmdiq5-menu {
  position:  relative !important;
} */

.loader-style {
  z-index: 1000;
  background-color: var(--loader-bg-color)
}

.global-state-true .accordion-item:nth-child(1) .accordion-button,
.global-state-true .accordion-item:nth-child(2) .accordion-button {
  background-color: var(--global-state-disable-bg-color);
  color: var(--global-state-disable-color);
}

.top-border-radius .accordion-item:nth-child(1) .accordion-button{
  border-radius: 0rem 0rem 0rem 0rem !important
}

.mvno-text-color{
  color: var(--mvnodashboard-text-color) !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  font-family: "Inter", sans-serif !important;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  font-family: "Inter", sans-serif !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  font-family: "Inter", sans-serif !important;
}

.css-1chpzqh {
  font-family: "Inter", sans-serif !important;
}

.css-1cccqvr {
  font-family: "Inter", sans-serif !important;
}

.css-1chpzqh {
  font-family: "Inter", sans-serif !important;
}

.row-options {
  font-family: "Inter", sans-serif !important;
}

.hr-width {
  width: 97%;
}

.invoice-text-color {
  color: var(--invoice-text-color);
}

.invoice-percent-and-icon-color {
  color: var(--orange-color);
}

.policy-text-length {
  width: 120px;
}

.policy-name-input-length {
  width: 35%;
}


.invoice-count {
  background-color: var(--orange-color);
}

.invoice-icon-size {
  font-size: 40px;
}

.badge-for-order {
  background-color: var(--white-color);
  border-radius: 0.75rem;
  font-size: 12px;
  border: 3px solid var(--main-color);
  border-color: 2px solid var(--main-color);
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-for-offer-count {
  border-radius: 100%;
  height: 13px;
  width: 13px;
  font-size: 9px;
  background-color: var(--main-color);
  color: var(--white-color);
}

.accordion-button {
  font-size: 14px !important;
  font-weight: 600 !important;

}

.accordion-button:not(.collapsed) {
  color: var(--black-color) !important;
  background-color: var(--white-color) !important;
  box-shadow: inset 0 calc(-1 * var(--vz-accordion-border-width)) 0 var(--vz-accordion-border-color);

}

.offer-name {
  font-size: 14px !important;
  font-weight: 500;
}

.offer-code-color {
  color: var(--offer-code-color);
}

.offer-status {
  color: var(--offer-status-color);
}

.Toastify__progress-bar--success {
  color: var(--toastify-icon-color-success)!important;
  background-color: var(--toastify-icon-color-success)!important;
}

.Toastify__progress-bar--error {
  color: var(--toastify-icon-color-error) !important;
  background-color: var(--toastify-icon-color-error) !important;
}

.nav-tabs-custom .nav-item .nav-link.active {
  color: var(--orange-color) !important;
  border-radius: 20px;
}

.sample-file {
  color: var(--main-color) !important;
}

.nav-tabs-custom.card-header-tabs .nav-link {
  color: var(--main-color) !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background: var(--main-color) !important;
  color: var(--white-color) !important;
}

.title-disable-color-edit-offer {
  color: var(--title-disable-color-edit-offer) !important;
}

.label-disable-color-edit-offer {
  color: var(--label-disable-color-edit-offer) !important;
}

.subscription-id {
  font-size: 11px;
  font-weight: 500;
}

.form-check-input:checked {
  background-color: var(--orange-color) !important;
  border-color: var(--orange-color) !important;
}

.header-selfcare-profile {
  background-color: var(--header-selfcare-profile) !important;
}

.border-selfcare-dashboard {
  border-color: var(--orange-color) !important;
}

.PhoneInputInput {
  border: none !important;
}

.PhoneInput {
  height: 37px !important;
}

.validation-font-size {
  font-size: 11.5px;
  margin-top: 3px;
}

.csv-file-color {
  color: var(--csv-file-color) !important
}

input[type=file]::file-selector-button {
  padding: .6em;
  border-radius: .4em;
  background-color: var(--main-color);
  color: var(--white-color);
  border-color: none;
}

.no-border-form {
  border: none !important;
}

input[type=file]:hover::file-selector-button {
  color: var(--white-color) !important;
  background-color: var(--file-button-color) !important;
}

.sample-file-color {
  color: var(--main-color);
  cursor: pointer;
}

.numeric-code { 
  color: #ADB5BD;
}

.cancel-button-background {
  background-color: var(--white-color) !important;
}

.cancel-button-background:hover {
  background-color: var(--main-color) !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 39px;
  border-radius: .5rem !important;
}

.css-1bn53lx {
  height: 39px !important;
  border-radius: .5rem !important;
}

.static-height {
  height: 340px;
}

.icon-size {
  font-size: 94px;
  color: #ADB5BD;
}

.text-color-telco {
  color: #ADB5BD 
}

.disable-margin-card {
  margin: 0px !important;
}
.color-box {
  width: 10px; 
  height: 10px; 
  display: inline-block;
  border-radius: 3px; 
}
.square-legend {
  display: inline-block;
  width: 13px;
  height: 14px;
}
.icon-lg {
  font-size: 1.2rem;
  color:#0078A3;
}

.border-for-task-card { 
  border: 1px solid var(--main-color) !important;
}

.table-border-none > thead > tr > th,
.table-border-none > tbody > tr > td {
    border-bottom: none !important;
}

.generate-button {
  background-color: var(--orange-color) !important;
  border-color: var(--orange-color);
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  height: auto;
  align-self: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 2px 10px !important;
  font-size: 14px !important;
  margin-right: 10px !important;
}

.generate-button i {
  margin-left: 3px;
  transform: rotate(45deg);
}

.ai-input-box {
  border: none !important;
}

.ai-input-box:focus {
  box-shadow: none !important;
  outline: none !important;
}

.ai-input-box-field {
  box-shadow: 0 4px 8px var(--light-blue-color)!important;
  border-radius: 15px !important;
  overflow: hidden !important;
  background-color: var(--white-color) !important;
  width: 95% !important;
  height: 65px !important;
}
.error-message-box {
  background-color: #FFD7CD;
  color: #721c24;
  padding: 15px;
  border-radius: 15px;
  margin-top: 15px;
  border-left: 15px solid var(--red-dark-color);
  display: flex;
  align-items: center;
  width: 94%;
  margin: 0 auto;
}

.error-message-box .icon {
  margin-right: 10px;
  font-size: 18px;
  color: var(--red-dark-color);
}

.message-container {
  margin-top: -40px;
}

.message-box.success {
  background-color: var(--white-color);
  color: var(--black-color);
  padding: 15px;
  border-radius: 15px;
  margin-top: 15px;
  border-left: 15px solid var(--main-color);
  display: flex;
  align-items: center;
  width: 94%;
  margin: 0 auto;
}

.message-box.success .icon {
  margin-right: 10px;
  font-size: 18px;
  color: var(--main-color);
}

.rotate-45 {
  transform: rotate(45deg);
}

.orange-main-colour{
  color:#fd7545                    
}

.set-current-plan {
  border: 2px solid #0AB39C;
  border-radius: 6px;
  padding: 3px;
  display: inline-block;
  color: #0AB39C;
}
.carousel-control-prev-icon {
  background-color: var(--orange-color)  !important; 
  color: var(--orange-color)   !important;
   background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(253,117,69,1)"><path d="M8.36853 12L13.1162 3.03212L14.8838 3.9679L10.6315 12L14.8838 20.0321L13.1162 20.9679L8.36853 12Z"></path></svg>') no-repeat center center !important;
    background-size: contain;
    width: 30px;
    height: 30px;
}
.carousel-control-next-icon {
  color: var(--orange-color)   !important;  background-image:none !important;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(253,117,69,1)"><path d="M15.6315 12L10.8838 3.03212L9.11622 3.9679L13.3685 12L9.11622 20.0321L10.8838 20.9679L15.6315 12Z"></path></svg>') no-repeat center center !important;
}
.border-for-customer-view{
  border-color: var(--csv-file-color)  !important;
}
.border-black {
  border-color: var(--csv-file-color)  !important;
}
.customer-view-screen{
  background-color: var(--customer-view-color) !important;
} 

.task-success-color {
  border: 2px solid var( --success-task-color);
  border-radius: 6px;
  color: var( --success-task-color);
  padding: 3px;
  padding-left: 15px;
  padding-right: 15px;
  
}

.task-fail-color {
  border: 2px solid var( --fail-task-color);
  border-radius: 6px;
  color: var( --fail-task-color);
  padding: 4px;
  padding-right: 20px;
  padding-left: 20px;
}
.task-inprogress-color {
  border: 2px solid var(--inprogress-task-color);
  border-radius: 6px;
  color: var(--inprogress-task-color);
  padding: 3px;
}
.task-pending-color {
  border: 2px solid var(--pending-task-color);
  border-radius: 6px;
  color: var(--pending-task-color);
  padding: 3px;
  padding-left: 15px;
  padding-right: 15px;
}
.set-status-user {
  border: 2px solid var(--blue-color);
  border-radius: 6px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  color:var(--blue-color);
}
.custom-border-lg-end {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-right: 1px solid black;
}


.image-icon-color {
  color: var(--main-color) !important;
  font-size: 45px !important;
}

.image-text-color {
  color: var(--image-text-color) !important;
}

.image-div-tag {
  position: relative;
  margin-bottom: 1rem;
}

.image-border-style {
  border: 1px dashed #ccc;
  border-radius: 7px;
  padding: 55px;
  text-align: center;
  max-width: 90%;
  margin-left: 20px;
  position: relative;
}

.to-img-tag {
  max-width: 100%;
  max-height: 100%;
}

.img-remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

.x-mark {
  color: red;
  font-size: 20px;
}

.cursor-img {
  cursor: pointer;
}

.logo-image-sm {
  max-height: 22px;
  width: auto;
}

.logo-image-lg {
  max-height: 25px; 
  width: auto;
}

.logo-sm {
  display: flex;
  align-items: center;
}

.logo-image {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.image-icon-color {
  color: var(--main-color) !important;
  font-size: 45px !important;
}

.image-text-color {
  color: var(--image-text-color) !important;
}

.image-div-tag {
  position: relative;
  margin-bottom: 1rem;
}

.image-border-style {
  border: 1px dashed #ccc;
  border-radius: 7px;
  padding: 55px;
  text-align: center;
  max-width: 90%;
  margin-left: 20px;
  position: relative;
}

.to-img-tag {
  max-width: 100%;
  max-height: 100%;
}

.img-remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

.x-mark {
  color: red;
  font-size: 20px;
}

.cursor-img {
  cursor: pointer;
}

.nav-tabs-custom .nav-item .nav-link.active {
  cursor: pointer;
}

.nav-tabs-custom.card-header-tabs .nav-link{
  cursor: pointer;
}
.avatarr {
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #8C8C8C;
  display: inline-block;
}
.svg-container {
  position: absolute;
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
  background: #b7d9e4;
  z-index: 0;
  overflow: hidden;
}
.svg-container .svg-wave1 {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 120px;
}
.svg-container .svg-wave2 {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100px;
}
.card-body-custom {
  position: relative;
  z-index: 2;
  margin-top: 20px;
  text-align: center;
}
.card-profile {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.task-color{
  color:var(--main-color) !important;
}
.css-18fgyve-MuiButtonBase-root-MuiIconButton-root{
  transform: rotate(90deg);
}
.css-4lhfkr{
  transform: rotate(90deg);
}
.css-lrgvhg {
  height: 38px !important;
  border-radius: .5rem !important;
}
.css-gcrcb4 {
  transform: rotate(90deg);
}

.checkbox-size {
  font-size: 11px;
}

.label-fixed-width {
  width: 150px;
}

/* Styles for round buttons */
.btn-circle {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #d3d3d3;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #a0a0a0;
  transition: background-color 0.3s ease;
}

.btn-circle:hover {
  background-color: #e0e0e0;
}

.quantity-input {
  width: 70px;
  height: 38px;
  text-align: center;
  border: 1px solid #ADB5BD4D;
  border-radius: 6px;
  font-size: 15px;
  color: #333;
  background-color: #fff;
}

.quantity-control {
  display: flex;
  align-items: center;
}
.border-for-balance{
  background-color: var(--main-color) !important;
  border-left: 8px solid #0078A3;
  height: 100%;
  margin-left: 32px;
}
.aggregate-balance-resource{
  font-size: 40px;
}
.balance-heading-color{
  color: #495057;
}
.label-color{
  color: var(--black-color) !important;

}
.preview-customer-1{
  height: 100%;
  width: 250%;
}
.preview-customer{
  height: 100%;
  width: 340%;
}
.balance-card {
  margin-top: 60px;
  margin-bottom: 60px;
}

.carousel-width {
  width: 3% !important;
}

.session-font-color {
  color: #007FFF;
}

.session-card-font-size {
  font-size: 40px !important;
}

.aggregate-resource-view {
  margin-bottom: 0px !important;
}

.logo {
  height: 40px;
}

.hero h1 {
  font-size: 2.63rem;
}
.hero h2 {
  font-size: 0.5rem;
}
.hero p {
  font-size: 1.2rem;
}

.transform h2 {
  font-size: 2rem;
}
.transform p {
  margin: 0 auto;
  line-height: 1.6;
}

.advantages h3 {
  font-size: 1.8rem;
}
.card-title {
  font-size: 1.2rem;
}
.main-color {
  color: #fd7545;
}
.main-bg-colour {
  background-color: #fd7545 !important;
}
.sub-heading {
  font-size: 30px !important;
  color: #ffffffbf;
}
.sub-heading-color {
  color: #ffffffbf;
}

.journey-section {
  position: relative;
  bottom: 90px;
  z-index: 1;
}

.journey-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.journey-title .main-color {
  color: #fd7545;
}

.journey-subtitle {
  font-size: 18px;
  font-weight: 500;
  color: #6c757d;
  margin-bottom: 40px;
}

.step-row {
  margin-bottom: 30px;
  align-items: center;
}

.step-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Steps */
.step-row {
  margin-bottom: 30px;
}

.step-circle {
  width: 50px !important;
  height: 50px;
  background-color: #113045;
  color: white;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .step-circle {
    width: 50px !important;
    height: 35px;
    font-size: 23px;
    margin-right: 10px !important;
  }
}

.step-circle-1 {
  width: 50px !important;
  height: 50px;
  background-color: #113045;
  color: white;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .step-circle-1 {
    width: 90px !important;
    height: 35px;
    font-size: 23px;
    margin-right: 10px !important;
  }
}

.step-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 8px;
}

.responsive-margin {
  margin-left: 0;
}

@media (min-width: 768px) {
  .responsive-margin {
    margin-right: 10rem;
  }
}
.responsive-margin-1 {
  margin-left: 0;
}

@media (min-width: 1025px) {
  .responsive-margin-1 {
    margin-left: 4rem;
  }
}

.circle-icon {
  width: 80px !important;
  height: 80px;
  border-radius: 50%;
  background-color: #113045;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-image {
  width: 40px; 
  height: 40px;
}

.carousel-container {
  position: relative;
  overflow: hidden;
  padding: 20px 0;
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-card {
  background-color: #1a2942;
  color: white;
  border-radius: 12px;
  padding: 2.5rem; 
  height: 300px;
  flex: 0 0 calc(40% - 20px);
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  word-wrap: break-word; 
  overflow: hidden; 
}

@media (max-width: 992px) {
  .carousel-card {
    flex: 0 0 calc(50% - 20px);
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .carousel-card {
    flex: 0 0 calc(80% - 20px); 
    padding: 1rem;
    font-size: 14px; 
    height: auto;
    max-width: 100%;
  }

  .carousel-card h3 {
    font-size: 20px; 
    margin-bottom: 1rem;
  }

  .carousel-card p {
    font-size: 14px; 
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .carousel-card {
    flex: 0 0 100%; 
    padding: 0.5rem; 
    font-size: 12px; 
  }

  .carousel-card h3 {
    font-size: 18px;
  }

  .carousel-card p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .carousel-card {
    flex: 0 0 calc(100% - 20px); /* 1 card visible */
  }
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 10px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #6c757d;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #ff7563;
}

.carousel-card h3 {
  font-size: 24px;
  margin-bottom: 1.5rem;
}

.carousel-card p {
  color: #d1d5db;
  font-size: 16px;
  line-height: 1.6;
}

.container {
  max-width: 1400px;
  padding: 2rem;
}

.main-title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 3rem;
  color: #4b5563;
}

.title-heading-color{
  color: #464A52; 
}
.step-title .break-text {
  display: block; 
}

@media (max-width: 1024px) { 
  .step-title .break-text {
      display: inline;
  }
}

.step-circle-2 {
  width: 50px !important;
  height: 50px;
  background-color: #113045;
  color: white;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .step-circle-2 {
    width: 76px !important;
    height: 35px;
    font-size: 23px;
    margin-right: 10px !important;
  }
}

.step-circle-3 {
  width: 50px !important;
  height: 50px;
  background-color: #113045;
  color: white;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .step-circle-3 {
    width: 100px !important;
    height: 35px;
    font-size: 23px;
    margin-right: 10px !important;
  }
}

.step-circle-4 {
  width: 50px !important;
  height: 50px;
  background-color: #113045;
  color: white;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .step-circle-4 {
    width: 80px !important;
    height: 35px;
    font-size: 23px;
    margin-right: 10px !important;
  }
}

.step-circle-5 {
  width: 50px !important;
  height: 50px;
  background-color: #113045;
  color: white;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .step-circle-5 {
    width: 65px !important;
    height: 35px;
    font-size: 23px;
    margin-right: 10px !important;
  }
}
.custom-accordion .accordion-button {
  background-color: #EEEEEE;
}

.custom-accordion .accordion-button:not(.collapsed) {
  background-color: #EEEEEE;
}


.sub-heading-color-landing{
  color: #113045;
}

.recent-customers-color {
  color: var(--recent-customer-color);
}

.category-offer-width {
  width: 240px;
}

.offer-bg-color {
  background-color: var(--category-offer-bg-color);
  border-radius: 7px !important;
}

.view-more-color {
  color: var( --view-more-color);
  cursor: pointer;
}

.live-session-status {
  margin-left: 1030px;
  color: var(--main-color) !important;
}
.live-session-status-1{
  margin-left: 1050px;
  color: var(--main-color) !important;
}
.live-session-status:hover,
.live-session-status-1:hover {
  color: #FD7545 !important;
}

.loader-style.disabled {
  pointer-events: none;
  opacity: 0.5; /* Optional: For visual feedback */
}

.tooltip-border-radius {
 border-radius: 10px !important;
}

.css-1umw9bq-MuiSvgIcon-root {
  transform: rotate(90deg);
}

.border-lg-start {
  border-left: 8px solid var(--main-color) !important;
}
.offer-description{
  font-size: 18px;
}
.offer-price-tag{
  background-color:#FD754533;;
}
.custom-prev {
  left: -110px !important; 
}

.custom-next {
  right: -110px !important; 
}
@media (max-width: 992px) {
  .custom-prev {
    left: -90px !important; 
  }
  
  .custom-next {
    right: -90px !important;
  }
}
@media (max-width: 768px) {
  .custom-prev {
    left: -30px !important; 
  }
  
  .custom-next {
    right: -30px !important;
  }
}
.new-plan{
  background-color: #0078A31A;
}
.plan-amount{
  color: #535353;
}
.selfcare-cardheight{
  height: 502px;
}
.changeplan-nav{
  color: black;
  text-decoration: none;
}
.custom-border-offer {
  border: var(--vz-border-width) var(--vz-border-style) #C8C8C8 !important;
}
.changeplan-description{
  margin-left: 2rem;
}
.tooltip-color-changeplan{
  color: var(--tool-tip-color);
}
/* .badge-for-changeplan {
  color: var(--main-color);
  background-color: white;
  border: 2px solid var(--main-color) !important;
} */

.badge-for-changeplan {
  border-color: var(--main-color) !important;
}
button.task-color:disabled {
  border-color: #0078A3 !important;
}
.close-button-currency {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}